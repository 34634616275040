import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Arrow';
import { LocalizationContext } from '../../../hoc/Localization';
import Link from '../../Link';
import styles from './styles.less';

const Header = ({ title, link, linkOptions }) => {
  const { t } = React.useContext(LocalizationContext);
  return (
    <div className={styles.header}>
      <h2>{title}</h2>
      {!!link && (
        <Link {...linkOptions}>
          <span>
            {t('test.result.see_more')}
            {' '}
            <Icon />
          </span>
        </Link>
      )}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.bool,
  linkOptions: PropTypes.shape({
    route: PropTypes.string,
    queryParams: PropTypes.object,
  }),
};

Header.defaultProps = {
  link: true,
  linkOptions: {
    route: 'index',
    queryParams: {},
  },
};

export default Header;
