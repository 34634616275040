import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';

const LinkWrapper = ({ data = {}, reference, children }) => {
  if (!data.link && !data.url) {
    return children;
  }

  return (
    <>
      <Link
        route="courseShow"
        school={data.school && data.school.link}
        course={data.link}
        id={data.shortId}
        title={data.title}
        queryParams={{ reference }}
      >
        {children}
      </Link>
    </>
  );
};

LinkWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  reference: PropTypes.string.isRequired,
};
export default LinkWrapper;
