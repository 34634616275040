import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Tooltip, notification } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Xing, Twitter, Linkedin, Facebook, VK } from 'react-social-sharing';
import { CopyOutlined } from '@ant-design/icons';
import Button from '../../Button';
import { SettingsContext } from '../../../hoc/Settings';
import { LocalizationContext } from '../../../hoc/Localization';
import styles from './styles.less';

const ShareModal = ({ onCancel, visible, course, article }) => {
  const { t, locale } = React.useContext(LocalizationContext);
  const {
    domainSettings: { domain },
  } = React.useContext(SettingsContext);

  const hideModal = () => {
    onCancel();
  };

  let link = '';
  if (course && course.school) {
    link = `https://${domain}/${locale}/courses/${course.school.link}/${course.link}/${course.shortId}`;
  } else {
    link = `https://${domain}/${locale}/blog/${article.year}/${article.month}/${article.day}/${article.url}`;
  }

  const openNotification = () => {
    notification.open({
      description: course ? t('course.link.copied') : t('article.link.copied'),
      style: {
        textAlign: 'center',
        paddingTop: 0,
      },
    });
  };

  return (
    <div className={styles.modal}>
      <Modal
        title={t('school.edit.toolbar.share')}
        visible={visible}
        onCancel={hideModal}
        footer={null}
        style={{ borderRadius: '8px' }}
      >
        <div className={styles.icons}>
          <div className={styles.icon} data_gh="share_facebook">
            <Tooltip title={t('course.share.facebook')} placement="top" color="white">
              <Facebook className={styles.shareButton} link={link} />
            </Tooltip>
          </div>
          <div className={styles.icon}>
            <Tooltip title={t('course.share.linkedin')} placement="top" color="white">
              <Linkedin className={styles.shareButton} link={link} />
            </Tooltip>
          </div>
          <div className={styles.icon}>
            <Tooltip title={t('course.share.twitter')} placement="top" color="white">
              <Twitter className={styles.shareButton} link={link} />
            </Tooltip>
          </div>
          {domain.includes('course.de') &&
            <div className={styles.icon}>
              <Tooltip title={t('course.share.xing')} placement="top" color="white">
                <Xing className={styles.shareButton} link={link} />
              </Tooltip>
            </div>}
          {domain.includes('course.ru') &&
            <div className={styles.icon}>
              <Tooltip title={t('course.share.vk')} placement="top" color="white">
                <VK className={styles.shareButton} link={link} />
              </Tooltip>
            </div>}
          <div>
            <CopyToClipboard
              text={link}
              onCopy={() => openNotification()}
            >
              <Button
                type="primary"
                className={styles.copyButton}
              >
                {t('course.share.copy')}
                {' '}
                <CopyOutlined />
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ShareModal.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  course: PropTypes.object,
  article: PropTypes.object,
};

ShareModal.defaultProps = {
  onCancel: () => { },
  visible: false,
  course: {},
  article: {},
};

export default ShareModal;
