import React from 'react';
import PropTypes from 'prop-types';
import ShareModal from '../ShareModal';

import styles from './styles.less';

const ShareAndFavourite = ({
  course,
  favouriteUrl,
  handleFavouriteClick,
  type,
  size,
}) => {
  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const handleShareClicked = () => {
    setShareModalOpen(!shareModalOpen);
  };

  return (
    <div className={styles[`shareAndFavourite${size}`]}>
      <div className={styles.shareCourse}>
        <div
          data_gh="share_button"
          onClick={handleShareClicked}
          className={
            type === 'search' ? styles.shareFavButtonSearch : styles[`shareFavButton${size}`]
          }
        >
          <span className={styles.imageWrapper}>
            <img
              className={styles[`shareIcon${size}`]}
              src="/static/images/course/share.svg"
              alt="share"
            />
          </span>
        </div>
        <ShareModal onCancel={handleShareClicked} visible={shareModalOpen} course={course} />
      </div>
      <div
        className={type === 'search' ? styles.shareFavButtonSearch : styles[`shareFavButton${size}`]}
        onClick={handleFavouriteClick}
      >
        <img className={styles[`favouriteIcon${size}`]} src={favouriteUrl} alt="favourite" />
      </div>
    </div>
  );
};

ShareAndFavourite.propTypes = {
  course: PropTypes.object,
  favouriteUrl: PropTypes.string,
  handleFavouriteClick: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.string,
};

ShareAndFavourite.defaultProps = {
  course: {},
  favouriteUrl: '',
  handleFavouriteClick: () => {},
  type: '',
  size: '',
};

export default ShareAndFavourite;
