import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { RouteContext } from './Route';
import Router from '../routes';
import { LocalizationContext } from './Localization';
import { UserContext } from './User';

const Login = dynamic(() => import('../components/Auth/Login'));

export const SecurityContext = React.createContext();

export const SecurityProvider = ({ children }) => {
  const { locale } = React.useContext(LocalizationContext);
  const { page } = React.useContext(RouteContext);
  const { user } = React.useContext(UserContext);
  const [register, setRegister] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [wizardVisible, setWizardVisible] = React.useState(false);
  const [review, setReview] = React.useState(false);
  const [favourite, setFavourite] = React.useState();

  const handleLogin = () => {
    setLogin(true);
  };

  const handleClose = () => {
    if (page === 'courseShow') {
      setRegister(false);
      setVisible(false);
    } else {
      setLogin(false);
      setVisible(false);
      setWizardVisible(false);
    }
    setReview(false);
    setFavourite('');
  };

  const handleClicked = shortId => {
    setFavourite(shortId);
  };

  const handleCancel = () => {
    setVisible(false);
    Router.pushRoute('addCourseIntro', {
      locale,
    });
  };

  // console.log({
  //   condition: (review ||
  //     wizardVisible ||
  //     login ||
  //     visible ||
  //     favourite ||
  //     (page === 'courseShow' && register)),
  //   review,
  //   wizardVisible,
  //   login,
  //   visible,
  //   favourite,
  //   "(page === 'courseShow' && register)": (page === 'courseShow' && register)
  // })

  return (
    <>
      <SecurityContext.Provider
        value={{
          setRegister,
          register,
          handleLogin,
          visible,
          setVisible,
          wizardVisible,
          setWizardVisible,
          review,
          setReview,
          favourite,
          setFavourite,
          handleClicked,
        }}
      >
        {children}
      </SecurityContext.Provider>
      {!user && page === 'createCourseSchool' && <Login visible onCancel={handleCancel} />}
      {!user &&
        (review ||
          wizardVisible ||
          login ||
          visible ||
          favourite ||
          (page === 'courseShow' && register)) && <Login visible onCancel={handleClose} />}
    </>
  );
};

SecurityProvider.propTypes = {
  children: PropTypes.any,
};

SecurityProvider.defaultProps = {
  children: null,
};
