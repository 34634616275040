const translits = require('./translits');

const ordered = { ' ': ' ' };
// eslint-disable-next-line
for (const index in translits) {
  // eslint-disable-next-line
  for (const key of Object.keys(translits[index])) {
    ordered[key] = translits[index][key];
  }
}

const translitString = (str = '', url = false) => {
  const separator = url ? '-' : ' ';
  let string = str;
  string = string.toLowerCase().replace('!s+!', ' ');
  string = string.replace('ու', 'u');

  // eslint-disable-next-line
  for (const letter of string.split('')) {
    const replace = ordered[letter];
    if (replace) {
      string = string.replace(letter, replace);
    }
  }

  string = string
    .replace(/[^0-9a-z]/gi, separator)
    .replace(/--+/g, separator)
    .replace(/  +/g, separator);

  return string;
};

module.exports = translitString;
