import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { LocalizationContext } from '../../hoc/Localization';
import { UserContext } from '../../hoc/User';
import Card from '../Card';
import Button from '../Button';
import Link from '../Link';
import styles from './styles.less';

const CardList = ({
  data,
  filters,
  type,
  noButton,
  moreButtonId,
  shuffle,
  recommended,
  reference,
}) => {
  const { t } = React.useContext(LocalizationContext);
  const { user } = React.useContext(UserContext);
  return (
    <div className={styles.container}>
      <Row className={styles.row} type="flex" gutter={20}>
        {data.map((item, index) => (
          <Col lg={8} md={12} sm={24} xs={24} key={item.id || index}>
            <Card data={item} type={type} user={user} reference={reference} />
          </Col>
        ))}
      </Row>
      {type !== 'event' && !noButton ? (
        <div className={styles.moreCoursesButton}>
          <Button version="large" type="primary" id={moreButtonId}>
            {filters.type === 'discounted' ? (
              <Link route="discount">
                <span>{t('test.result.more.courses.button')}</span>
              </Link>
            ) : (
                <Link route="search" queryParams={{ ...filters }}>
                  <span>{t('test.result.more.courses.button')}</span>
                </Link>
              )}
          </Button>
        </div>
      ) : null}
      {recommended === 'recommended' && (
        <div className={styles.moreCoursesButton}>
          <Button size="large" type="primary" id={moreButtonId} onClick={shuffle}>
            <Link route="search" queryParams={{ ...filters }}>
              <span>{t('test.result.more.courses.button')}</span>
            </Link>
          </Button>
        </div>
      )}
    </div>
  );
};

CardList.propTypes = {
  data: PropTypes.array,
  noButton: PropTypes.bool,
  filters: PropTypes.object,
  moreButtonId: PropTypes.string,
  type: PropTypes.oneOf(['course', 'event']),
  shuffle: PropTypes.func,
  recommended: PropTypes.string,
  reference: PropTypes.string,
};

CardList.defaultProps = {
  data: [],
  filters: {},
  type: 'course',
  noButton: false,
  moreButtonId: '',
  shuffle: () => { },
  recommended: '',
  reference: '',
};

export default CardList;
