import React from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd';

import styles from './card-item.less';

const CardItem = ({ imageUrl, alt, title, description, minimal = false, style = {} }) => (
  <>
    {minimal ? (
      <div style={style}>
        <div style={styles.imageContainer}>
          <img src={imageUrl} alt={alt} />
        </div>
        <b style={{ textAlign: 'center' }}>{title}</b>
      </div>
    ) : (
      <div className={styles.cardWrapper}>
        <Card
          className={styles.card}
          style={style}
          cover={
            <div className={styles.imageContainer}>
              <img src={imageUrl} alt={alt} />
            </div>
          }
        >
          <Card.Meta
            title={title}
            description={
              <Skeleton active loading={false}>
                <span className={styles.description}>{description}</span>
              </Skeleton>
            }
          />
        </Card>
      </div>
    )}
  </>
);

CardItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  minimal: PropTypes.bool,
  style: PropTypes.object,
};

CardItem.defaultProps = {
  minimal: false,
  style: {},
};

export default CardItem;
