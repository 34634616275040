import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';

const Rating = ({ rating, favourites, visits }) => (
  <div className={styles.wrapper}>
    <span className={styles.searchComponent}>
      <img src="/static/images/course/starBackground.svg" className={styles.star} alt="star" />
      <span className={styles.rating}>{rating || '-'}</span>
    </span>
    {favourites && (
      <span className={`${styles.heart} ${styles.searchComponent}`}>
        <img src="/static/images/course/heartBackground.svg" alt="favourite" />
        <span className={styles.favouriteNumber}>{favourites || 0}</span>
      </span>
    )}
    {visits ? (
      <span className={styles.searchComponent}>
        <img src="/static/images/course/show.svg" className={styles.star} alt="star" />
        <span className={styles.rating}>{visits}</span>
      </span>
    ) : null}
  </div>
);

Rating.propTypes = {
  rating: PropTypes.number,
  visits: PropTypes.number,
  favourites: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Rating.defaultProps = {
  rating: 0,
  favourites: '',
  visits: 0,
};

export default Rating;
