import { sendPubSubEvent } from '../api';

const formatCourse = (course, currency = 'AMD') => ({
  id: course.id,
  name: course.title,
  price: Math.round(course.price / (currency === 'AMD' ? 480 : 1)),
  brand: course.school ? course.school.title : null,
  category: course.category ? course.category.title : null,
});

export const coursesImpressed = (courses = [], params = {}) => {
  const { list = 'Search Results', currentPage = 1, perPage = 12 } = params;

  window.dataLayer.push({
    event: 'productImpressions',
    ecommerce: {
      impressions: courses.map((course, index) => ({
        ...formatCourse(course),
        position: perPage * (currentPage - 1) + index + 1,
        list,
      })),
    },
  });
};

export const coursesSearched = async filters => {
  await sendPubSubEvent('user.searched.courses', filters);
};

export const courseClicked = async (course = {}, params = {}) => {
  if (!course || !course.id) {
    return;
  }

  const { list = 'Search Results' } = params;

  window.dataLayer.push({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list },
        products: [formatCourse(course)],
      },
    },
  });

  await sendPubSubEvent('user.visited.course', {
    id: course.id,
    shortId: course.shortId,
    externalId: course.external_id,
  });
};

export const courseVisited = async (course = {}) => {
  if (!course || !course.id) {
    return;
  }

  window.dataLayer.push({
    ecommerce: {
      detail: {
        products: [formatCourse(course)],
      },
    },
  });

  await sendPubSubEvent('user.visited.course', {
    id: course.id,
    shortId: course.shortId,
    externalId: course.external_id,
    portalId: course.portalId,
  });
};
