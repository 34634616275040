import React from 'react';
//@ts-ignore
import Router, { Link } from '../../routes';
import { LocalizationContext } from '../../hoc/Localization';
import { RouteContext } from '../../hoc/Route';

interface CustomLinkProps {
  route: string,
  locale?: string,
  to?: string,
  onClick?: () => any,
  className?: string,
  queryParams?: { [key: string]: any },
  title?: string,
  style?: { [key: string]: any },
  target?: string,
}

const CustomLink: React.FC<CustomLinkProps> = ({
  route,
  locale: newLocale = null,
  queryParams = {},
  children = null,
  className = null,
  title = null,
  style = {},
  to = '',
  onClick = () => { },
  target = '',
  ...props
}): JSX.Element => {
  const { locale } = React.useContext(LocalizationContext);
  const { page } = React.useContext(RouteContext);

  const child = (
    <a
      style={style}
      title={title}
      //@ts-ignore
      to={to}
      onClick={onClick}
      target={target}
      className={[className, route === page ? 'active' : null].join(' ')}
    >
      {children}
    </a>
  );

  if (page === '_error') {
    // @TODO check what this part is for?
    // @ts-ignore
    return React.Children.map(child, element =>
      React.cloneElement(element, {
        href: `/${locale}${Router.getRoutePath(route, queryParams)}`,
      }),
    );
  }

  return (
    <Link route={route} locale={newLocale || locale} queryParams={queryParams} {...props}>
      {child}
    </Link>
  );
};

export default CustomLink;
