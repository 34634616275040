import React from 'react';
import PropTypes from 'prop-types';
import { courseClicked } from '../../utils/events/courses';
import styles from './styles.less';

const Wrapper = ({ data, type, children }) => {
  if (type === 'course') {
    if (!data.link && !data.url) {
      return children;
    }

    return (
      <div className={styles.wrapper} onClick={() => courseClicked(data)}>
        {children}
      </div>
    );
  }
  if (type === 'wizard') {
    if (!data.link && !data.url) {
      return children;
    }

    return <>{children}</>;
  }

  if (type === 'event') {
    return (
      <a href={data.url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return children;
};

Wrapper.propTypes = {
  type: PropTypes.oneOf(['course', 'event']),
};
export default Wrapper;
