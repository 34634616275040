import React from 'react';
import { LocationContext } from '../../../hoc/Location';
import { LocalizationContext } from '../../../hoc/Localization';
import { SettingsContext } from '../../../hoc/Settings';
import Link from '../../Link';
import styles from './styles.less';

const PredefinedLinks = () => {
  const { location } = React.useContext(LocationContext);
  const { t } = React.useContext(LocalizationContext);
  const { center, zoom } = location;
  const {
    domainSettings: { prefered },
  } = React.useContext(SettingsContext);

  return (
    <div className={styles.links}>
      {`${t('frontpage.predefined.courses')}:`}
      {Object.keys(prefered).map((key, index) => (
        <span id={`predef${index}`} key={key}>
          <Link route="search" queryParams={{ ...center, zoom, ...prefered[key] }}>
            {`${key}`}
          </Link>
        </span>
      ))}
    </div>
  );
};

export default PredefinedLinks;
