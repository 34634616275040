import React from 'react';
import styles from './styles.less';
import { LocalizationContext } from '../../../hoc/Localization';

const Stats = () => {
  const { t } = React.useContext(LocalizationContext);

  const stats = [
    {
      title: t('frontpage.statistic.1'),
      value: t('frontpage.statistic.number.1'),
      color: '#80359780',
    },
    {
      title: t('frontpage.statistic.2'),
      value: t('frontpage.statistic.number.2'),
      color: 'transparent',
    },
    {
      title: t('frontpage.statistic.3'),
      value: t('frontpage.statistic.number.3'),
      color: '#fa4b9780',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.stats}>
        {stats.map(stat => (
          <div style={{ backgroundColor: stat.color, borderColor: stat.color }} key={stat.title}>
            <div>
              <p>{stat.value}</p>
              <p className="buttonText">{stat.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stats;
