import React from 'react';
import { SettingsContext } from '../hoc/Settings';
import { UserContext } from '../hoc/User';

export default () => {
  const {
    domainSettings: { region, defaultCurrency, rewardUsePercent, registerRewardCurrency },
  } = React.useContext(SettingsContext);
  const { user } = React.useContext(UserContext);

  const priceFormat = React.useCallback(
    price =>
      new Intl.NumberFormat(
        region,
        region === 'DE' && {
          style: 'currency',
          currency: defaultCurrency,
        },
      )
        .format(price)
        .replace(/[AMD$RUB€]/g, ''),
    [],
  );

  const applyDiscount = (price, course) => {
    const {
      currency,
      discount,
      discounted_price: discountedPrice,
      monthly_price: monthlyPrice,
      last_minute_start: lastMinuteStart,
      last_minute_end: lastMinuteEnd,
      last_minute_discount: lastMinuteDiscount,
      last_minute_message: lastMinuteMessage,
      early_bird_start: earlyBirdStart,
      early_bird_end: earlyBirdEnd,
      early_bird_discount: earlyBirdDiscount,
      early_bird_message: earlyBirdMessage,
      discount_start: discountStart,
      discount_end: discountEnd,
    } = course;


    const now = new Date();
    const earlyStart = new Date(earlyBirdStart);
    const earlyEnd = new Date(earlyBirdEnd);
    const lastStart = new Date(lastMinuteStart);
    const lastEnd = new Date(lastMinuteEnd);
    const discountStarts = new Date(discountStart);
    const discountEnds = new Date(discountEnd);
    const earlyPrice = price - earlyBirdDiscount;
    const lastPrice = price - lastMinuteDiscount;

    let currentPrice = price;
    let amount;
    let percent;

    if (discount && discountedPrice !== 0 && discountStarts < now && now < discountEnds) {
      currentPrice = price - discountedPrice;
      percent = Math.round((discountedPrice / price) * 100);
      amount = discountedPrice
    }

    if (earlyStart < now && now < earlyEnd && earlyPrice < currentPrice) {
      currentPrice = earlyPrice;
      amount = price - currentPrice;
      percent = Math.round((amount / price) * 100);
    } else if (lastStart < now && now < lastEnd && lastPrice < currentPrice) {
      currentPrice = lastPrice;
      amount = price - currentPrice;
      percent = Math.round((amount / price) * 100);
    }


    return {
      price: currentPrice,
      amount,
      percent
    }
  }

  const getCoursePrice = React.useCallback(course => {
    const { price, currency, period, free_course: isFreeCourse } = course

    let computedPrice = price

    // apply discount
    const {
      price: discountedPrice,
      amount: discountAmount,
      percent: discountPercent
    } = applyDiscount(computedPrice, course)

    if (discountedPrice) {
      computedPrice = discountedPrice
    }

    // apply reward/bonus
    let rewardedAmount = null
    if (user && user.balance && rewardUsePercent && computedPrice) {
      rewardedAmount = Math.min(Math.ceil((computedPrice * rewardUsePercent) / 100), user.balance)
      computedPrice -= rewardedAmount
    }

    return {
      isFreeCourse,
      initial: {
        price,
        currency,
        period,
      },
      discount: {
        amount: discountAmount,
        percent: discountPercent,
        price: discountedPrice
      },
      reward: {
        amount: rewardedAmount,
        percent: rewardUsePercent,
        currency: registerRewardCurrency
      },
      final: {
        price: computedPrice
      }
    }
  }, [user, rewardUsePercent, registerRewardCurrency])

  return {
    priceFormat,
    getCoursePrice
  };
};
