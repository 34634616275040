import React from 'react';
import PropTypes from 'prop-types';
import { LocalizationContext } from '../../../hoc/Localization';

const ScheduleMoment = ({ data, style, check, noDate, noTime }) => {
  const { t } = React.useContext(LocalizationContext);

  const date = new Date(data.closest_schedule_date);
  const scheduleHours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
  const scheduleMinutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
  const closestTime = scheduleHours ? `${scheduleHours}:${scheduleMinutes}` : null;
  const closestDay = date.getDate();

  const closestMonth = new Date(`${data.closest_schedule_date}`).toLocaleDateString('en-En', {
    month: 'short',
  });
  const days = [
    t('course.schedule.days.sunday'),
    t('course.schedule.days.monday'),
    t('course.schedule.days.tuesday'),
    t('course.schedule.days.wednesday'),
    t('course.schedule.days.thursday'),
    t('course.schedule.days.friday'),
    t('course.schedule.days.saturday'),
  ];
  const month = {
    Jan: t('course.month.jan'),
    Feb: t('course.month.feb'),
    Mar: t('course.month.march'),
    Apr: t('course.month.april'),
    May: t('course.month.may'),
    Jun: t('course.month.june'),
    Jul: t('course.month.july'),
    Aug: t('course.month.aug'),
    Sep: t('course.month.sep'),
    Oct: t('course.month.oct'),
    Nov: t('course.month.nov'),
    Dec: t('course.month.dec'),
  };
  const closestWeekDay = days[date.getDay()];
  return data.closest_schedule_date ? (
    <div className={style.dateSchedule}>
      {check && <div className={style.weekDaySchedule}>{`${closestWeekDay}, `}</div>}
      {!noDate && (
        <>
          <div className={style.closeMonth}>{month[closestMonth]}</div>
          <div className={style.closest}>{closestDay}</div>
        </>
      )}
      <div className={style.closeTime}>{!data.noClosestScheduleTime && !noTime && closestTime}</div>
    </div>
  ) : (
      check && <div className={style.noSchedule}>{t('no.schedule.front_page')}</div>
    );
};

ScheduleMoment.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  check: PropTypes.bool,
  noDate: PropTypes.bool,
  noTime: PropTypes.bool,
};

ScheduleMoment.defaultProps = {
  data: {},
  style: {},
  check: false,
  noDate: false,
  noTime: false,
};

export default ScheduleMoment;
