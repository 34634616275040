import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';

const Container = ({ children, className, name }) => (
  <section className={`${styles.section} ${className}`} name={name}>
    <div className="container">{children}</div>
  </section>
);

Container.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.any,
  name: PropTypes.string,
};

Container.defaultProps = {
  className: '',
  name: '',
};

export default Container;
