import React from 'react';
import Router from '../../routes';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, Form, Input, notification } from 'antd';
import { LocalizationContext } from '../../hoc/Localization';
import { RouteContext } from '../../hoc/Route';
import { LocationContext } from '../../hoc/Location';
import { SearchContext } from '../../hoc/Search';
import styles from './styles.less';

const CourseSearch = ({ type, category, placement, name, handleFocusChange }) => {
  const { t, locale } = React.useContext(LocalizationContext);
  const { location } = React.useContext(LocationContext);
  const { filters, pushFilters } = React.useContext(SearchContext);
  const { center, zoom } = location;
  const [search, setSearch] = React.useState(filters.search);
  const { page } = React.useContext(RouteContext);
  const [searchDate, setSearchDate] = React.useState();
  const [showMobileDatePicker, setShowMobileDatePicker] = React.useState(false);
  const [showUnderline, setShowUnderline] = React.useState({ startDate: false, endDate: false });
  const placeholder = t('search.example') || 'Search';
  const dontLetSearch = !search && !searchDate;
  React.useEffect(() => {
    if (page !== 'search') {
      setSearch('');
    } else {
      setSearch(search);
    }
  }, []);
  const onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    const queryParams = { ...center, zoom, search, date: filters.date || [] };

    if (!queryParams.search) {
      delete queryParams.search;
    }

    if (category) {
      queryParams.category = category;
    }

    const queryString = `?${Object.keys(queryParams)
      .map(key => `${key}=${queryParams[key]}`)
      .join('&')}`;

    Router.pushRoute('search', {
      locale,
      queryParams
    });
  };
  const warning = () => {
    notification.warning({
      message: t('warning.keyword'),
    });
  };
  const classNameForSearchButton = () => {
    if (page === 'index') {
      if (dontLetSearch) {
        return styles.searchWrapper;
      }
      return styles.searchButton;
    }
    return styles.searchWrapper;
  };
  function showStartDateUnderline() {
    if (showUnderline.startDate) {
      return 'showStartDateUnderline';
    }
    return '';
  }
  function showEndDateUnderline() {
    if (showUnderline.endDate) {
      return 'showEndDateUnderline';
    }
    return '';
  }
  return (
    <div className={styles.mainContainer}>
      <Form
        className={`${styles.search} ${styles[type]} ${placement === 'header' ? styles.searchPage : ''
          }`}
        key={filters.search}
      >
        <div className={styles.container}>
          <Input
            id="coverSearch"
            name={name}
            defaultValue={filters.search}
            placeholder={placeholder}
            onSelect={() => {
              handleFocusChange(true);
              setShowMobileDatePicker(true);
            }}
            onPressEnter={dontLetSearch ? () => warning() : onSubmit}
            onInput={e => setSearch(e.target.value)}
            suffix={
              <div
                onClick={dontLetSearch ? () => warning() : onSubmit}
                className={classNameForSearchButton()}
                name={type && `${type}_${name}`}
              >
                <img
                  className={`${placement === 'header' ? styles.searchPageIcon : styles.blogPageIcon
                    }`}
                  src="/static/images/search.svg"
                  alt="search"
                />
              </div>
            }
            className={styles.blogSearchInput}
          />
          <div className={styles.horizontalDivider}>
            <div />
          </div>
          <div
            className={[
              styles.dateContainer,
              type === 'default' && showMobileDatePicker && styles.dateContainerMobile,
            ].join(' ')}
          >
            <DatePicker
              value={
                filters.date && filters.date.length && filters.date[0]
                  ? moment(filters.date[0])
                  : null
              }
              format="MMM DD"
              className={styles.date}
              dropdownClassName="dropdown"
              suffixIcon={[]}
              placeholder={t('course.details.content.start_date')}
              onChange={date => {
                let data = [];
                const value = date ? date.format('YYYY-MM-DD') : null;
                setSearchDate(value);
                if (filters.date && filters.date.length) {
                  data = [...filters.date];
                  data[0] = value;
                } else {
                  data = date ? [value] : [];
                }

                filters.search = search;

                pushFilters({ ...filters, date: data });
              }}
            />
            <span className={styles.span}>-</span>
            <DatePicker
              value={filters.date && filters.date.length === 2 ? moment(filters.date[1]) : null}
              format="MMM DD"
              className={[styles.date, styles.lastDate].join(' ')}
              dropdownClassName="dropdown"
              suffixIcon={[]}
              placeholder={t('form.dates.end')}
              onChange={date => {
                let data = [];
                const value = date ? date.format('YYYY-MM-DD') : null;
                if (filters.date && filters.date.length) {
                  data = [...filters.date];
                  if (date) {
                    if (filters.date.length === 1) {
                      data.push(value);
                    } else {
                      data[1] = value;
                    }
                  } else if (filters.date.length !== 1) {
                    data.pop();
                  }
                } else {
                  data = [null, date.format('YYYY-MM-DD')];
                }
                filters.search = search;

                pushFilters({ ...filters, date: data });
              }}
            />
          </div>
        </div>
      </Form>
      <div
        style={{
          height: showMobileDatePicker && type === 'default' ? 75 : 0,
        }}
        className={styles.mobileSearchContainer}
      >
        <div
          className={[styles.mobileStartDate, styles[showStartDateUnderline()]].join(' ')}
          onClick={() => setShowUnderline({ startDate: true, endDate: false })}
        >
          <div>{t('course.details.content.start_date')}</div>
          <div>
            <DatePicker
              value={
                filters.date && filters.date.length && filters.date[0]
                  ? moment(filters.date[0])
                  : null
              }
              inputReadOnly
              format="MMM DD"
              onMouseDown={() => setShowUnderline({ startDate: true, endDate: false })}
              className={styles.datePickerMobile}
              dropdownClassName={styles.dropDown}
              suffixIcon={[]}
              style={{
                border: 'none',
                paddingLeft: 0,
                paddingBottom: 0,
                cursor: 'pointer',
                width: '100%',
              }}
              placeholder={t('add.search.date')}
              onChange={date => {
                let data = [];
                const value = date ? date.format('YYYY-MM-DD') : null;
                setSearchDate(value);
                if (filters.date && filters.date.length) {
                  data = [...filters.date];
                  data[0] = value;
                } else {
                  data = date ? [value] : [];
                }

                filters.search = search;
                pushFilters({ ...filters, date: data });
              }}
            />
          </div>
        </div>
        <div
          className={[styles.mobileEndDate, styles[showEndDateUnderline()]].join(' ')}
          onClick={() => setShowUnderline({ startDate: false, endDate: true })}
        >
          <div>{t('form.dates.end')}</div>
          <div>
            <DatePicker
              value={filters.date && filters.date.length === 2 ? moment(filters.date[1]) : null}
              format="MMM DD"
              inputReadOnly
              className={styles.datePickerMobile}
              dropdownClassName={styles.dropDown}
              suffixIcon={[]}
              onMouseDown={() => setShowUnderline({ startDate: false, endDate: true })}
              placeholder={t('add.search.date')}
              style={{
                border: 'none',
                paddingLeft: 0,
                paddingBottom: 0,
                cursor: 'pointer',
                width: '100%',
              }}
              onChange={date => {
                let data = [];
                const value = date ? date.format('YYYY-MM-DD') : null;
                if (filters.date && filters.date.length) {
                  data = [...filters.date];
                  if (date) {
                    if (filters.date.length === 1) {
                      data.push(value);
                    } else {
                      data[1] = value;
                    }
                  } else if (filters.date.length !== 1) {
                    data.pop();
                  }
                } else {
                  data = [null, date.format('YYYY-MM-DD')];
                }
                filters.search = search;

                pushFilters({ ...filters, date: data });
              }}
            />
          </div>
        </div>
      </div>
      {showMobileDatePicker && type === 'default' && (
        <div
          className={styles.backDrop}
          onClick={() => {
            handleFocusChange(false);
            setShowMobileDatePicker(false);
            setShowUnderline({ startDate: false, endDate: false });
          }}
        />
      )}
    </div>
  );
};

CourseSearch.propTypes = {
  type: PropTypes.string,
  category: PropTypes.string,
  placement: PropTypes.string,
  name: PropTypes.string,
  handleFocusChange: PropTypes.func,
};

CourseSearch.defaultProps = {
  type: 'default',
  handleFocusChange: () => { },
  category: null,
  placement: '',
  name: '',
};

export default CourseSearch;
