import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import { Card, Skeleton } from 'antd';
import { LocalizationContext } from '../../hoc/Localization';
import { SecurityContext } from '../../hoc/Security';
import { SettingsContext } from '../../hoc/Settings';
import { UserContext } from '../../hoc/User';
import Rating from '../Rating';
import usePriceFormat from '../../utils/priceFormat';
import ScheduleMoment from '../CourseComponents/ScheduleMoment';
import { toggleFavourite } from '../../utils/directory';
import styles from './styles.less';
import Wrapper from './Wrapper';
import LinkWrapper from './LinkWrapper';
import ShareAndFavourite from '../TinyComponents/ShareAndFavourite';

const CustomCard = ({ data, type, reference }) => {
  const [loading, setLoading] = React.useState(true);
  const [favourites, setFavourites] = React.useState(data.favouriteNumber);
  const [isFavourite, setIsFavourite] = React.useState(false);
  const [loadingFavourite, setLoadingFavourite] = React.useState(false);

  const { t, locale } = React.useContext(LocalizationContext);
  const { favourite: favouriteClicked, setFavourite, handleClicked } = React.useContext(
    SecurityContext,
  );
  const { user, refreshUser } = React.useContext(UserContext);
  const {
    domainSettings: { domain },
  } = React.useContext(SettingsContext);

  const symbolCurrency = {
    AMD: '֏',
    USD: '$',
    EUR: '€',
    RUB: '₽',
  };
  const { priceFormat } = usePriceFormat();

  // eslint-disable-next-line no-nested-ternary
  const currentPeriod = data.period
    ? data.period !== 'custom'
      ? t(`course.details.content.${data.period}`)
      : data.period_value
    : '';

  React.useEffect(() => {
    if (Object.keys(data).length) {
      setLoading(false);
    }
  }, [Object.keys(data)]);

  React.useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (user && user.favourites) {
      // eslint-disable-next-line react/prop-types
      const favourite = user.favourites.find(f => f.course_id === data.shortId);
      if (favourite && !isFavourite) {
        setIsFavourite(true);
      }
      if (!favourite && isFavourite) {
        setIsFavourite(false);
      }
    }
  }, [user]);

  React.useEffect(() => {
    if (favouriteClicked && user) {
      if (favouriteClicked === data.shortId) {
        setFavourite('');
        // eslint-disable-next-line no-use-before-define
        handleFavouriteClick();
      }
    }
  }, [favouriteClicked, user]);

  async function handleFavouriteClick() {
    if (user && !loadingFavourite) {
      setLoadingFavourite(true);
      await toggleFavourite(data.shortId, isFavourite, 'course');
      await refreshUser();
      if (isFavourite) {
        setFavourites(Number(favourites) - 1);
      } else {
        setFavourites((Number(favourites) || 0) + 1);
      }
      setLoadingFavourite(false);
    }
    if (!user) {
      handleClicked(data.shortId);
    }
  }

  let content;

  if (type === 'event') {
    content = (
      <div className={styles.date}>
        <span className={styles.black}>{`${moment(data.date).format('DD')} `}</span>
        <span>{moment(data.date).format('MMM')}</span>
        {!!data.end_date && (
          <>
            <span> - </span>
            <span className={styles.black}>{`${moment(data.end_date).format('DD')} `}</span>
            <span>{moment(data.end_date).format('MMM')}</span>
          </>
        )}
      </div>
    );
  } else if (type === 'course' || type === 'wizard') {
    const school = data.school || {};

    if (school.link) {
      content = <> </>;
    }
  } else {
    content = <Card.Meta title={data.title} />;
  }
  const utc = new Date();

  const formatURL = src => {
    if (src) {
      if (!src.includes('fbcdn')) {
        const urlFront = src.substring(0, src.lastIndexOf('.'));
        const ext = src.slice(src.lastIndexOf('.'), src.length);
        return urlFront.concat('-medium').concat(ext);
      }
      return src;
    }
    return null;
  };

  // eslint-disable-next-line no-unused-vars
  let { discount } = data;
  let earlyBirdDiscount = data.early_bird_discount;
  let lastMinuteDiscount = data.last_minute_discount;
  const outdatedDiscount = () => {
    if (
      (discount &&
        data.discount_start < utc.toISOString() &&
        data.discount_end > utc.toISOString()) ||
      (earlyBirdDiscount &&
        data.early_bird_start < utc.toISOString() &&
        data.early_bird_end > utc.toISOString()) ||
      (lastMinuteDiscount &&
        data.last_minute_start < utc.toISOString() &&
        data.last_minute_end > utc.toISOString())
    ) {
      return false;
    }
    return true;
  };

  if (data.discount_start > utc.toISOString() || data.discount_end < utc.toISOString()) {
    discount = 0;
  }

  if (data.early_bird_start > utc.toISOString() || data.early_bird_end < utc.toISOString()) {
    earlyBirdDiscount = 0;
  }

  if (data.last_minute_start > utc.toISOString() || data.last_minute_end < utc.toISOString()) {
    lastMinuteDiscount = 0;
  }

  let favouriteUrl = '/static/images/course/heartBlack.svg';
  if (isFavourite) {
    favouriteUrl = '/static/images/course/heartChecked.svg';
  }

  let discountedPrice;

  if (discount) {
    discountedPrice =
      data.discount_type === 'percentage'
        ? data.price - (data.price * discount) / 100
        : data.price - discount;
  }

  if (earlyBirdDiscount) {
    discountedPrice = data.price - earlyBirdDiscount;
  }

  if (lastMinuteDiscount) {
    discountedPrice = data.price - lastMinuteDiscount;
  }

  return (
    <Wrapper data={data} type={type}>
      <div className={styles.cardContainer}>
        <Card
          className={styles.card}
          cover={
            <div className={styles.imageContainer}>
              <LinkWrapper data={data} reference={reference}>
                <LazyLoad>
                  <img
                    className={styles.courseImage}
                    src={formatURL(data.image)}
                    alt={data.title}
                  />
                </LazyLoad>
              </LinkWrapper>
              <div className={styles.shareAndFavourite}>
                <ShareAndFavourite
                  course={data}
                  domain={domain}
                  favouriteUrl={favouriteUrl}
                  handleFavouriteClick={handleFavouriteClick}
                  locale={locale}
                  school={data.school}
                />
              </div>
            </div>
          }
        >
          <div className={styles.general_sch}>
            <ScheduleMoment style={styles} data={data} check />
            {/* {startDate} */}
            {data.free_trial ? (
              <span className={styles.freeTrialCourse}>
                <img
                  className={styles.bottomImage}
                  src="/static/images/course/freeTrial.svg"
                  alt="FreeTrial"
                />
                <span>free trial</span>
              </span>
            ) : null}
          </div>
          <Card.Meta
            title={
              <Skeleton loading={loading} active paragraph={{ rows: 3 }} style={{ width: '80%' }}>
                <div style={{ overflow: 'hidden', maxWidth: '233px', width: 'fit-content' }}>
                  {data.title && (
                    <LinkWrapper data={data} reference={reference}>
                      <h1 style={{ overflow: 'hidden' }} name="wizard_course_title">
                        {data.title}
                      </h1>
                    </LinkWrapper>
                  )}
                </div>
              </Skeleton>
            }
            description={
              <>
                {type !== 'event' && !loading ? (
                  <span className={styles.description}>
                    {(discount || earlyBirdDiscount || lastMinuteDiscount) &&
                    !outdatedDiscount() ? (
                      <div className={styles.discounted_courses}>
                        <span className={styles.discounted_price}>
                          {`${priceFormat(data.price)} ${symbolCurrency[data.currency]}`}
                        </span>
                        <span className={styles.discounted_price}>
                          {`${priceFormat(discountedPrice)} ${symbolCurrency[data.currency]}`}
                        </span>
                      </div>
                      ) : (
                        <div className={styles.initialPrice}>
                          {!data.price && !data.free_course && 'N/A'}
                          {data.price > 0 &&
                          !data.free_course &&
                          `${priceFormat(data.price)} ${symbolCurrency[data.currency]}`}
                          {data.free_course && t('details.course.free')}
                        </div>
                      )}
                  </span>
                ) : null}
                <div className={styles.footerContainer}>{content}</div>
              </>
            }
          />
          <div className={styles.footerCardInfo}>
            <Rating rating={data.rating} favourites={favourites || '0'} />
            <div className={styles.currentPeriodPos}>{currentPeriod}</div>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};

CustomCard.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  reference: PropTypes.string.isRequired,
};

CustomCard.defaultProps = {
  type: 'course',
};

export default CustomCard;
