import React from 'react';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from '../utils/cookies';
import { getDomainSettings } from '../utils/api';

export const LocationContext = React.createContext();

export const LocationProvider = ({ children, locations, location: propsLocation }) => {
  const [location, setLocation] = React.useState(propsLocation);
  const countries = locations.filter(l => l.default).map(l => l.country);

  React.useEffect(() => {
    setLocation(propsLocation);
  }, [propsLocation]);

  return (
    <LocationContext.Provider
      value={{
        countries,
        locations,
        location: {
          center: {
            lat: parseFloat(location.center.lat),
            lng: parseFloat(location.center.lng),
          },
          zoom: parseInt(location.zoom, 10),
        },
        setLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const withLocation = WrappedComponent =>
  class extends React.PureComponent {
    render() {
      return (
        <LocationContext.Consumer>
          {props => <WrappedComponent {...props} {...this.props} />}
        </LocationContext.Consumer>
      );
    }
  };

LocationProvider.propTypes = {
  children: PropTypes.any,
  locations: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

LocationProvider.defaultProps = {
  children: null,
};

LocationProvider.getInitialProps = async (ctx, router) => {
  const locations = [];

  if (process.browser) {
    const { pathname, components } = router;
    if (components[pathname] && components[pathname].props) {
      locations.push(...components[pathname].props.locationProps.locations);
    }
  } else {
    const {
      req: {
        headers: { host },
      },
    } = ctx;

    const domainSettings = await getDomainSettings(host);
    (domainSettings.locations || []).map((item, index) => {
      if (index === 0) {
        item.default = true;
      }

      locations.push(item);
    });
  }

  const defaultLocation = locations.find(l => l.default === true);

  const {
    query: { lat, lng, zoom },
  } = ctx;

  const ctxLocation = lat && lng && zoom ? { center: { lat, lng }, zoom } : {};
  const cookieLocation = getCookie('location') || {};

  // eslint-disable-next-line
  const location = {
    ...defaultLocation,
    ...cookieLocation,
    ...ctxLocation,
  };

  setCookie('location', location);

  return {
    locations,
    location,
  };
};
