import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';

const TitleContent = ({ title, content, className }) => {
  const titleComponent = typeof title === 'string' ? <h2>{title}</h2> : title;
  return (
    <div className={className}>
      <div className={styles.title}>{titleComponent}</div>
      <div>{content}</div>
    </div>
  );
};

TitleContent.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
  className: PropTypes.string,
};

TitleContent.defaultProps = {
  className: '',
  title: '',
  content: '',
};

export default TitleContent;
