import React from 'react';
import { Button as AntButton } from 'antd';
import styles from './styles.less';
import { ButtonProps, ButtonShape } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const propsByVersion: {
  [key: string]: {
    size?: SizeType,
    shape?: ButtonShape,
    className?: string
  }
} = {
  large: {
    size: 'large',
    //shape: 'default',
    className: styles.large,
  },
  default: {
    size: 'middle',
    //shape: 'default',
    className: styles.default,
  },
  small: {
    size: 'small',
    //shape: 'default',
    className: styles.small,
  },
  cancel: {
    className: styles.cancel,
  },
};

export interface ButtonPropsInterface extends ButtonProps {
  version?: 'large' | 'default' | 'small' | 'cancel'
  children?: any,
  className?: string
}

const Button: React.FC<ButtonPropsInterface> = ({ version = 'default', children = '', className = '', ...rest }) => {
  const { className: propsClassName = '', ...props } = propsByVersion[version];

  return (
    <AntButton {...props} className={`${propsClassName} ${className}`} {...rest}>
      {children}
    </AntButton>
  );
};

export default Button;
