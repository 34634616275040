import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { LocalizationContext } from '../../../hoc/Localization';
import Stats from '../Stats';
import CourseSearch from '../../CourseSearch';
import PredefinedLinks from '../PredifinedLinks';
import styles from './styles.less';

const Cover = ({ landingUrl, landingUrlSmall }) => {
  const { t } = React.useContext(LocalizationContext);

  const width = global.window && global.window.innerWidth;

  const [safari, setSafari] = React.useState(false);

  React.useEffect(() => {
    if (window && !window.navigator.userAgent.includes('Chrome')) {
      setSafari(true);
    }
  }, []);

  return (
    <div className={styles.cover}>
      <LazyLoad offset={500}>
        <div
          className={styles.slider}
          style={{
            backgroundImage: !safari
              ? `url(${width > 768 ? landingUrl : landingUrlSmall})`
              : 'url(https://storage.googleapis.com/tutor.berlin/settings/home.jpeg)',
          }}
        />
      </LazyLoad>
      <div className={styles.content}>
        <div className={`container ${styles.container}`}>
          <div className={styles.inner}>
            <h1>{t('frontpage.search.title')}</h1>

            <div>
              <CourseSearch type="cover" />
              <PredefinedLinks />
            </div>
          </div>
        </div>

        <div className={styles.stats}>
          <Stats />
        </div>
      </div>
    </div>
  );
};

Cover.propTypes = {
  landingUrl: PropTypes.string,
  landingUrlSmall: PropTypes.string,
};

Cover.defaultProps = {
  landingUrl: '',
  landingUrlSmall: '',
};

export default Cover;
