import React from 'react';
import PropTypes from 'prop-types';
import Router from '../routes';
import { sortingKeys } from '../utils/directory';
import { RouteContext } from './Route';

export const SearchContext = React.createContext();

export const SearchProvider = ({
  children,
  locale,
  desktopCount: propsDesktopCount,
  mobileCount: propsMobileCount,
  filters: propsFilters,
}) => {
  const { page: routePage } = React.useContext(RouteContext);

  const [activeMarker, setActiveMarker] = React.useState(null);
  const [hoverMarker, setHoverMarker] = React.useState(null);
  const [filtersVisible, setFiltersVisible] = React.useState(false);
  const [filtersCount, setFiltersCount] = React.useState({ desktop: 0, mobile: 0 });
  const [filters, setFilters] = React.useState(propsFilters);

  const toggleFilters = (force = undefined) => {
    const visible = force !== undefined ? force : !filtersVisible;

    const body = document.querySelector('body');
    body.classList.remove('modal');
    if (visible) {
      body.classList.add('modal');
    }

    setFiltersVisible(visible);
  };

  const pushFilters = value => {
    const { location, page = 1, ...append } = filters;

    const queryParams = {
      ...append,
      ...value,
      lat: location ? location.center.lat : null,
      lng: location ? location.center.lng : null,
      zoom: location ? location.zoom : null,
      countries: null,
      location: null,
      page: value.page || +page,
    };

    if (queryParams.page === 1) {
      delete queryParams.page;
    }
    // eslint-disable-next-line
    for (const key of Object.keys(queryParams)) {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    }
    setFiltersVisible(false);

    if (routePage === 'search') {
      Router.pushRoute('search', {
        locale,
        queryParams: { ...queryParams },
      });
    } else {
      setFilters({ ...filters, ...value });
    }
  };

  React.useEffect(() => {
    setFilters(propsFilters);
  }, [propsFilters]);

  React.useEffect(() => {
    setFiltersCount({
      desktop: propsDesktopCount,
      mobile: propsMobileCount,
    });
  }, [propsDesktopCount, propsMobileCount]);

  return (
    <SearchContext.Provider
      value={{
        filtersVisible,
        filtersCount,
        filters,
        activeMarker,
        hoverMarker,
        setActiveMarker,
        setHoverMarker,
        toggleFilters,
        pushFilters,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const withSearch = WrappedComponent =>
  class extends React.PureComponent {
    render() {
      return (
        <SearchContext.Consumer>
          {props => <WrappedComponent {...props} {...this.props} />}
        </SearchContext.Consumer>
      );
    }
  };

SearchProvider.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
  filters: PropTypes.object.isRequired,
  desktopCount: PropTypes.number,
  mobileCount: PropTypes.number,
};

SearchProvider.defaultProps = {
  children: null,
  locale: null,
  desktopCount: 0,
  mobileCount: 0,
};

SearchProvider.getInitialProps = async ctx => {
  const {
    params: { locale, lat, lng, zoom, price, date, time, ...filters },
  } = Router.getMatchingRoute(ctx.asPath);

  filters.sort = sortingKeys.indexOf(filters.sort) !== -1 ? filters.sort : sortingKeys[0];

  if (filters.id && filters.school) {
    filters.school = filters.id;
    delete filters.id;
  }

  if (lat && lng && zoom) {
    filters.location = {
      center: {
        lat,
        lng,
      },
      zoom,
    };
  }

  if (price) {
    filters.price = price.split(',');
  }

  if (date) {
    filters.date = date.split(',');
  }

  if (time) {
    filters.time = time.split(',');
  }

  const desktopCount = Object.keys(filters).filter(
    key =>
      ![
        'location',
        'title',
        'page',
        'search',
        'category',
        'rating',
        'verified',
        'price',
        'sort',
      ].includes(key),
  ).length;

  const mobileCount = Object.keys(filters).filter(
    key => !['location', 'title', 'page', 'search', 'sort'].includes(key),
  ).length;

  return {
    locale,
    filters,
    desktopCount,
    mobileCount,
  };
};
