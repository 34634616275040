import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import directory from '@tutorbot/directory-api';
import { SettingsContext } from '../../hoc/Settings';
import { LocationContext } from '../../hoc/Location';
import { LocalizationContext } from '../../hoc/Localization';
import { UserContext } from '../../hoc/User';
import { getCourses } from '../../utils/directory';
import Cover from '../../components/Home/Cover/index';
import CardList from '../../components/CardList';
import TitleContent from '../../components/TinyComponents/TitleContent';
import Section from '../../components/Home/Section';
import styles from './styles.less';
import Container from '../../components/Home/Container';
import Header from '../../components/CardList/Header';
import useOnScreen from '../../components/useOnScreen';
import Professions from '../../components/Paths/pathNew/Professions';
import { getPopularPaths } from '../../utils/api';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Subscribe = dynamic(() => import('../../components/Home/Subscribe'));
const JsonLD = dynamic(() => import('../../components/JsonLD'));
const Explore = dynamic(() => import('../../components/Home/Explore'));
const Categories = dynamic(() => import('../../components/Home/Categories'));
const Blog = dynamic(() => import('../../components/Home/Blog'));
const EmailLogin = dynamic(() => import('../../components/Auth/EmailLogin'));

const Home = () => {
  const defaultArticles = [{}, {}, {}];

  const {
    domainSettings: {
      blogCountry,
      facebook: { testLink },
      landingUrl,
      landingUrlSmall,
      internalSchoolId,
      domain,
    },
  } = React.useContext(SettingsContext);
  const { countries } = React.useContext(LocationContext);
  const { t, locale, blogLocale } = React.useContext(LocalizationContext);
  const { user } = React.useContext(UserContext);

  const [recommendedCourses, setRecommendedCourses] = React.useState([{}, {}, {}, {}, {}, {}]);
  const [discountedCourses, setDiscountedCourses] = React.useState([{}, {}, {}, {}, {}, {}]);
  const [hotCourses, setHotCourses] = React.useState({ data: [{}, {}, {}, {}, {}, {}] });
  const [categories, setCategories] = React.useState([{}, {}, {}, {}, {}]);
  const [blogArticles, setBlogArticles] = React.useState(defaultArticles);
  const [recomendedFetched, setRecomendedFetched] = React.useState(false);
  const [discountedFetched, setDiscountedFetched] = React.useState(false);
  const [categoriesFetched, setCategoriesFetched] = React.useState(false);
  const [paths, setPaths] = React.useState([]);

  const [events, setEvents] = React.useState([{}, {}, {}]);

  const firstRef = React.useRef();
  const secondRef = React.useRef();
  const thirdRef = React.useRef();
  React.useEffect(() => {
    const windowWidth = window.screen.width;

    if (!internalSchoolId) {
      (async () => {
        if (windowWidth > 768) {
          setRecommendedCourses(
            (await getCourses({ filters: { countries, type: 'recommended' }, limit: 6 })).data,
          );
          setDiscountedCourses(
            (
              await getCourses({
                filters: { countries, type: 'discounted' },
                limit: 6,
              })
            ).data,
          );
        }
        setHotCourses(await getCourses({ filters: { countries, type: 'hot' }, limit: 6 }));
        if (windowWidth > 768) {
          setCategories((await directory().categories.get()).data);
        }
        setEvents(
          (
            await directory()
              .events.limit(3)
              .where('location.country', countries)
              .sort({ date: 'desc' })
              .get()
          ).data,
        );
        setPaths(await getPopularPaths(domain));
      })();
    }
  }, []);
  React.useEffect(() => {
    if (!internalSchoolId) {
      (async () => {
        setBlogArticles(
          (
            await directory()
              .blog.limit(3)
              .where('categories', blogCountry)
              .where('categories', blogLocale)
              .whereNot('categories', 50)
              .whereNot('categories', 65)
              .whereNot('categories', 85)
              .whereNot('categories', 101)
              .sort({ date: 'desc' })
              .get()
          ).data,
        );
      })();
    }
  }, [blogLocale]);

  const recommendationsFetching = useOnScreen(firstRef, '-150px');
  const categoriesFetching = useOnScreen(thirdRef, '150px');

  if (!recomendedFetched && recommendationsFetching && !internalSchoolId) {
    setRecomendedFetched(true);
    (async () => {
      setRecommendedCourses(
        (await getCourses({ filters: { countries, type: 'recommended' }, limit: 6 })).data,
      );
    })();
  }

  if (
    !discountedFetched &&
    recommendationsFetching &&
    !(discountedCourses[0] && discountedCourses[0].id) &&
    !internalSchoolId
  ) {
    (async () => {
      setDiscountedCourses(
        (await getCourses({ filters: { countries, type: 'discounted' }, limit: 6 })).data,
      );
      setDiscountedFetched(true);
    })();
  }

  if (categoriesFetching && !categoriesFetched && !internalSchoolId) {
    (async () => {
      setCategoriesFetched(true);
      setCategories((await directory().categories.get()).data);
    })();
  }

  const fetchRecommended = async () => {
    setRecommendedCourses(
      (await getCourses({ filters: { countries, type: 'recommended' }, limit: 6 })).data,
    );
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>{`${t('meta.title.suffix')} | ${t('meta.title.front')}`}</title>
        <meta name="description" content={t('meta.description.front')} />
      </Head>

      <Cover landingUrl={landingUrl} landingUrlSmall={landingUrlSmall} />

      {/* -------------- Hot Courses -------------- */}
      <div ref={firstRef}>
        <Container>
          <TitleContent
            title={
              <>
                <h2>{t('frontpage.courses.hot.title')}</h2>
                <p className={styles.subTitle}>{t('frontpage.courses.hot.description')}</p>
              </>
            }
            content={
              <CardList
                moreButtonId="moreHot"
                data={hotCourses.data}
                filters={{ type: 'hot' }}
                noButton={!hotCourses.total || hotCourses.total <= 6}
                reference="hotCourses"
              />
            }
          />
        </Container>
      </div>

      {/* -------------- Messenger -------------- */}

      {internalSchoolId && (
        <Container className={[styles.invert, styles.test].join(' ')}>
          <Section
            title={t('messenger.title')}
            description={t('messenger.subtitle')}
            button={
              <a href={testLink} target="_blank" id="messengerTestButton" rel="noopener noreferrer">
                {t('messenger.take.test')}
              </a>
            }
            icon="/static/images/frontpage/messenger.svg"
          />
        </Container>
      )}

      {/* -------------- Recommended Courses -------------- */}
      {recommendedCourses && recommendedCourses[0] && recommendedCourses[0].id && (
        <Container>
          <TitleContent
            title={
              <>
                <h2>{t('frontpage.courses.recommended.title')}</h2>
                <p className={styles.subTitle}>{t('frontpage.courses.recommended.description')}</p>
              </>
            }
            content={
              <CardList
                data={recommendedCourses}
                noButton
                moreButtonId="moreRecommended"
                recommended={user && 'recommended'}
                filters={{ type: 'recommended' }}
                shuffle={fetchRecommended}
                reference="recommended"
              />
            }
          />
        </Container>
      )}
      {/* Discounted anf free courses */}
      {!internalSchoolId && (
        <Container>
          <TitleContent
            title={
              <>
                <h2>{t('frontpage.courses.free.discount')}</h2>
                <p className={styles.subTitle}>{t('frontpage.discount.description')}</p>
              </>
            }
            content={
              <CardList
                data={discountedCourses}
                moreButtonId="moreDiscounted"
                filters={{ type: 'discounted' }}
                reference="discounted"
              />
            }
          />
        </Container>
      )}

      {!!paths && !!paths.length && (
        <Container>
          <h2>{t('courses.professions')}</h2>
          <div className={styles.professions}>
            {paths.map(p => (
              <Professions
                key={p.id}
                id={p.id}
                image={p.image}
                title={p[`title_${locale}`]}
                profession={p.profession[`title_${locale}`]}
                description={p[`description${locale}`] || p.description}
              />
            ))}
          </div>
        </Container>
      )}

      {/* -------------- Explore -------------- */}
      {recomendedFetched && (
        <div ref={secondRef}>
          <Container className={[styles.invert, styles.test].join(' ')}>
            <Explore />
          </Container>
        </div>
      )}
      {/* -------------- Events -------------- */}

      {events[0] && events[0].id && categoriesFetched && (
        <Container>
          <TitleContent
            title={<Header title={t('details.events.title')} link={false} />}
            content={<CardList data={events} type="event" reference="events" />}
          />
        </Container>
      )}

      {/* -------------- Blog -------------- */}

      {blogArticles[0] && !!blogArticles[0].id && categoriesFetched && (
        <Container>
          <TitleContent
            title={
              <>
                <Header
                  title={t('footer.blog')}
                  linkOptions={{
                    route: 'blog',
                  }}
                />
                <p className={styles.subTitle}>{t('frontpage.blog.description')}</p>
              </>
            }
            content={<Blog articles={blogArticles} />}
          />
        </Container>
      )}

      {/* -------------- Categories -------------- */}

      {categories[0] && categories[0].id && categoriesFetched && (
        <Container name="allCategories">
          <TitleContent
            title={
              <>
                <h2>{t('search.category')}</h2>
                <p className={styles.subTitle}>{t('frontpage.categories.description')}</p>
              </>
            }
            content={<Categories categories={categories} />}
          />
        </Container>
      )}

      {/* -------------- Subscribe -------------- */}
      {recomendedFetched && (
        <div id="subscribe" ref={thirdRef}>
          <Container className={styles.subscribe}>
            <Subscribe categories={['front page subscription']} />
          </Container>
          <JsonLD type="front" />
        </div>
      )}
      {/* -------------- Email Login -------------- */}

      <EmailLogin />
    </div>
  );
};

export default Home;
