import React from 'react';
import PropTypes from 'prop-types';
import translitString from '../../../TranslitString';
import { LocalizationContext } from '../../../../hoc/Localization';
import CardItem from '../../components/cart-item/card-item';
import Link from '../../../Link';

const Profession = ({ image, title, id, profession, description }) => {
  const { locale } = React.useContext(LocalizationContext);

  return (
    <Link
      route="paths"
      locale={locale}
      profession={profession ? translitString(profession, true) : 'profession'}
      name={translitString(title, true)}
      id={id}
    >
      <CardItem alt={title} imageUrl={image} description={description} title={title} />
    </Link>
  );
};

Profession.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  profession: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Profession;
