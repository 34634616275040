import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import styles from './styles.less';

const Section = ({ title, description, button, icon }) => (
  <div className={styles.container}>
    <div style={{ width: '100%' }}>
      <h2>{title}</h2>
      <h3>{description}</h3>
    </div>
    <div className={styles.bottomContainer}>
      <Button version="large" type="primary" className={styles.buttonContainer}>
        {button}
      </Button>
      <div className={styles.imageContainer}>
        <img src={icon} alt={title} className={styles.image} />
      </div>
    </div>
  </div>
);

Section.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.any.isRequired,
  icon: PropTypes.string,
};

Section.defaultProps = {
  title: '',
  description: '',
  icon: '',
};

export default Section;
