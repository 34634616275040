import React from 'react';
require('intersection-observer');

export default function useOnScreen(ref, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (!isIntersecting && entry.isIntersecting === true) {
          setIntersecting(entry.isIntersecting);
        }
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => ref.current && observer.unobserve(ref.current);
  }, [ref.current]);
  return isIntersecting;
}
